/* Bootstrap  */
import "bootstrap";
import { Modal } from 'bootstrap';

/* Custom SCSS */
import "./index.scss";

/* SwiperJS */
// import Swiper, { Navigation, Pagination, Scrollbar, Grid } from 'swiper';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';

// import 'swiper/css/grid';
//import 'swiper/css/scrollbar';
//Swiper.use([Scrollbar, Navigation, Pagination, Grid]);

/* Fancybox */
//import { Fancybox } from "@fancyapps/ui";
//import "@fancyapps/ui/dist/fancybox.css";

/* Gsap */
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);

window.onYouTubeIframeAPIReady = function () {
    jQuery('.block-gallery__video-container').each(function() {
        var blockid = jQuery(this).data('id');
        var videoid = jQuery(this).data('videoid');
        console.log(videoid)
        new YT.Player( blockid, {
            height: '360',
            width: '640',
            videoId: videoid,
            playerVars: {
                controls: 0, // Nasconde i controlli
                modestbranding: 1, // Rimuove il branding di YouTube
                showinfo: 0, // Nasconde le informazioni sul video
                rel: 0, // Non mostra video correlati
                autoplay: 1, // Avvia automaticamente
                mute: 1, // Mette in muto il video
                loop: 1, // Loop continuo
                playlist: videoid
            },
            events: {
                onReady: function (event) {
                    event.target.playVideo(); // Avvia automaticamente
                }
            }
        });
          
    })
}

// Start Jquery
jQuery(document).ready( function($) {



    // scroll to top
    if($('.scrollToTopBtn').length) {
        var scrollToTopBtn = document.querySelector(".scrollToTopBtn");
        var rootElement = document.documentElement;
        function handleScroll() {
            var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
            if (rootElement.scrollTop / scrollTotal > 0.7) {
                scrollToTopBtn.classList.add("showBtn");
            } else {
                scrollToTopBtn.classList.remove("showBtn");
            }
        }
        function scrollToTop() {
            rootElement.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
        scrollToTopBtn.addEventListener("click", scrollToTop);
        document.addEventListener("scroll", handleScroll);
    }
});

// Start vanilla js
document.addEventListener('DOMContentLoaded', () => {

    // Menu Custom
    const menuItems = document.querySelectorAll('.menu-item, .submenu-item');

    menuItems.forEach(item => {
        item.addEventListener('click', (event) => {
            event.stopPropagation();  // Prevenire la propagazione dell'evento

            // Chiudere i sottomenu non pertinenti
            closeOtherSubmenus(item);

            // Aprire il sottomenu corrente, se esiste
            const submenu = item.querySelector('.submenu');
            if (submenu) {
                submenu.style.display = submenu.style.display === 'flex' ? 'none' : 'flex';
            }
        });
    });

    // Chiudere i sottomenu cliccando fuori dal menu
    document.addEventListener('click', () => {
        document.querySelectorAll('.submenu').forEach(submenu => {
            submenu.style.display = 'none';
        });
    });

    function closeOtherSubmenus(currentElement) {
        document.querySelectorAll('.submenu').forEach(submenu => {
            // Chiude solo i sottomenu che non sono discendenti dell'elemento cliccato
            if (!currentElement.contains(submenu) && !submenu.contains(currentElement)) {
                submenu.style.display = 'none';
            }
        });
    }

    const toggler = document.querySelector('.navbar-toggler-custom');
    const menu = document.querySelector('#navbarNavAltMarkup');

    toggler.addEventListener('click', function () {
        document.documentElement.classList.toggle('overflow-hidden');
        document.body.classList.toggle('menu-open');
    });

    menu.addEventListener('hidden.bs.collapse', function () {
        document.documentElement.classList.remove('overflow-hidden');
        document.body.classList.remove('menu-open');
    });

    menu.addEventListener('shown.bs.collapse', function () {
        document.documentElement.classList.add('overflow-hidden');
        document.body.classList.add('menu-open');
    });

    // Gsap elements
    const progressSections = document.querySelectorAll('.progress-section');

    progressSections.forEach(section => {
        const progressContainers = section.querySelectorAll('.progress-container');

        ScrollTrigger.create({
            trigger: section,
            start: "top center",
            onEnter: () => {
                progressContainers.forEach(container => {
                    const progressBar = container.querySelector('.progress-bar');
                    const progressPerc = container.querySelector('.progress-perc strong');
                    let endValue = parseInt(container.getAttribute('data-end'), 10);

                    let newValue = endValue < 20 ? 20 : endValue;

                    console.log(endValue);

                    gsap.to(progressBar, {
                        width: newValue + "%",
                        ease: "power1.out",
                        duration: 0.5
                    });

                    gsap.to(progressPerc, {
                        textContent: endValue,
                        ease: "power1.out",
                        duration: 0.5,
                        snap: { textContent: 1 },
                        onUpdate: function() {
                            progressPerc.textContent = Math.round(progressPerc.textContent) + "%";
                        }
                    });
                });
            }
        });
    });

    // Funzione per animare gli elementi
    function animateOnce(elem, xValue) {
        // Verifica se l'elemento è già stato animato
        if (!elem.getAttribute('data-animated')) {
            gsap.fromTo(elem,
                { x: xValue, opacity: 0 },
                {
                    x: 0,
                    opacity: 1,
                    duration: 1.25,
                    ease: "expo",
                    overwrite: "auto",
                    onComplete: () => {
                        elem.setAttribute('data-animated', 'true'); // Imposta il flag
                    }
                }
            );
        }
    }

    // Targeting elementi con .fade-left
    gsap.utils.toArray(".fade-left").forEach(function(elem) {
        ScrollTrigger.create({
            trigger: elem,
            start: "top 80%",  // Quando inizia l'animazione
            toggleActions: "play none none none",
            onEnter: function() { animateOnce(elem, -100) },
        });
    });

    // Targeting elementi con .fade-right
    gsap.utils.toArray(".fade-right").forEach(function(elem) {
        ScrollTrigger.create({
            trigger: elem,
            start: "top 80%",  // Quando inizia l'animazione
            toggleActions: "play none none none",
            onEnter: function() { animateOnce(elem, 100) },
        });
    });

    gsap.utils.toArray(".fade-in").forEach(function(elem) {
        if (!elem.classList.contains('fade-left') && !elem.classList.contains('fade-right')) {
            ScrollTrigger.create({
                trigger: elem,
                start: "top 80%",  // Quando inizia l'animazione
                onEnter: function() { animateOnce(elem) },
                toggleActions: "play none none none"
            });
        }
    });

    const items = gsap.utils.toArray('.timeline-step');
    if(items) {
        items.forEach((item, index) => {
            if (index % 2 === 0) {
                ScrollTrigger.create({
                    trigger: item,
                    start: "top center", // Inizio del trigger quando il top del trigger è al centro della viewport
                    end: "bottom center",
                    onEnter: () => {
                        item.querySelector('h2').classList.add('active');
                    }
                });
            }
        });
    }

    // Swiper elements
    const swiperSlider = new Swiper('.slider_story .swiper_desktop', {
        slidesPerView: 1,
        navigation: {
            nextEl: '.slider_story .swiper-button-next',
            prevEl: '.slider_story .swiper-button-prev'
        }
    });
    const swiperSliderStoryMobile = new Swiper('.slider_story .swiper_mobile', {
        slidesPerView: 1,
        navigation: {
            nextEl: '.slider_story .swiper-button-next',
            prevEl: '.slider_story .swiper-button-prev'
        }
    });

    const slider_log = new Swiper('.slider_logistica.swiper', {
        //centeredSlides: true,
        slidesPerView: 1,
        pagination: {
            el: '.slider_logistica .swiper-pagination',
            type: 'bullets',
            clickable:true
        },
        breakpoints: {
            576: {
                slidesPerView: 2,
            },
            991: {
                slidesPerView: 4,
            },
            1200: {
                slidesPerView: 5,
            }
        }
    });

    
    const slider_immagini = new Swiper('.slider-immagini .swiper', {
        //centeredSlides: true,
        slidesPerView: 1,
        pagination: {
            el: ".swiper-pagination",
        },
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
    });

    const slider_immagini_semplice = new Swiper('.slider-immagini-semplice .swiper', {
        //centeredSlides: true,
        slidesPerView: 1,
        pagination: {
            el: ".swiper-pagination",
        },
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
    });

    const slider_text = new Swiper('.slider_text.swiper', {
        //centeredSlides: true,
        slidesPerView: 1,
        pagination: {
            el: '.slider_text .swiper-pagination',
            type: 'bullets',
            clickable:true
        },
    });

    const slider_deposits_img = new Swiper('.deposito-slider-img.swiper', {
        //centeredSlides: true,
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.deposito-slider-img .swiper-pagination',
            type: 'bullets',
            clickable:true
        },
    });

    const slider_deposits = new Swiper('.deposits_slider.swiper', {
        //centeredSlides: true,
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.deposits_slider .swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        navigation: {
            nextEl: '.deposits_slider .swiper-button-next',
            prevEl: '.deposits_slider .swiper-button-prev'
        }
    });

    const slider_video = new Swiper('.slider-video .swiper', {
        slidesPerView: 1,
        loop: true,
        rewind: true,
        spaceBetween: 20,
        autoHeight: true,
        pagination: {
            el: '.slider-video .swiper-pagination',
            type: 'bullets',
        },
        navigation: {
            nextEl: '.slider-video .swiper-button-next',
            prevEl: '.slider-video .swiper-button-prev',
        },
        breakpoints: {
            992: {
                slidesPerView: 1.5,
            }
        }
    });

    var players = document.querySelectorAll('.player');
    var videos = document.querySelectorAll('video');

    players.forEach(function(player, index) {
        var video = videos[index];
        player.addEventListener('click', function () {
            player.style.display = 'none';
            video.play();
        });

        // controllo se ha l'attributo autoplay e in quel caso metto display none all'icon player
        if (video.hasAttribute('autoplay')) {
            player.style.display = 'none';
        }

        video.addEventListener('click', function () {
            if (video.paused) {
                video.play();
                player.style.display = 'none';
            } else {
                video.pause();
                player.style.display = 'block';
            }
        });

        video.addEventListener('ended', function () {
            player.style.display = 'block';
        });

    });

    // deposit slider
    $('.citta_bullet').each(function() {

        $(this).on('click', function() {

            var titolo_paese = $(this).data('name');
            var tot = $(this).data('tot');
            var surgelato = $(this).data('surgelato');
            var fresco = $(this).data('fresco');
            var ambiente = $(this).data('ambiente');

            if(titolo_paese!=='') $('.titolo-paese').html(titolo_paese);
            if(tot!=='') $('.tot').html(tot);
            if(surgelato!=='') {
                $('.surgelato_wrapper').show();
                $('.surgelato').html(surgelato); 
            } else {
                $('.surgelato_wrapper').hide();
            }
            if(fresco!=='') {
                $('.fresco_wrapper').show();
                $('.fresco').html(fresco); 
            } else {
                $('.fresco_wrapper').hide();
            }
            if(ambiente!=='') {
                $('.ambiente_wrapper').show();
                $('.ambiente').html(ambiente);
            } else {
                $('.ambiente_wrapper').hide();
            }

            var depositoModal = new Modal(document.getElementById('depositoModal'));
            depositoModal.show();
        
        })
    })
});
